<template>
  <state-item item-class="speakers-items">
    <div class="speakers-wrapper">
      <div class="flex-img-speakers">
        <div
          :style="{
            background: item.photoUrl
              ? 'url(' + item.photoUrl + ')'
              : 'url(' + require('@/assets/img/user.png') + ')'
          }"
          class="photo-speaker"
        ></div>
        <div class="name-speaker">
          <router-link :to="{ name: 'SingleSpeaker', params: { id: item.id } }">
            {{ item.name }}
          </router-link>
        </div>
      </div>
      <advanced-tags
        :content-tags="item.categories"
        :special-tags="item.special_categories"
      />
      <p class="pre-speaker">{{ item.shortDescription }}</p>
      <img class="img-flag" :src="countryFlag" :alt="item.country" />
    </div>
  </state-item>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
import AdvancedTags from "@/components/page/items/tags/AdvancedTags";
export default {
  name: "SpeakerTile",
  components: { AdvancedTags, StateItem },
  props: {
    item: Object
  },
  computed: {
    countryFlag() {
      return require("@/assets/img/flag/" +
        (this.item.countryFlagCode ? this.item.countryFlagCode : "russia") +
        ".svg");
    }
  }
};
</script>

<style scoped></style>
