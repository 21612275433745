<template>
  <div class="inner-news-tovar">
    <div class="speakers-list" v-if="tiles && tiles.length">
      <speaker-tile :item="item" v-bind:key="item.key" v-for="item in tiles" />
    </div>
  </div>
</template>

<script>
import SpeakerTile from "@/components/page/speaker/tiles/SpeakerTile";
export default {
  name: "SpeakerTiles",
  components: { SpeakerTile },
  props: {
    tiles: Array
  }
};
</script>

<style scoped></style>
