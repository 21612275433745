<template>
  <show-more-block
    :show-more="showMore"
    :update-list="updateList"
    :sort-type="sortType"
    :sort="sort"
    :page="page"
    :size="15"
    :init-size.sync="data.length"
  >
    <speaker-tiles :tiles="data" />
    <div class="clear"></div>
  </show-more-block>
</template>

<script>
import SpeakerTiles from "@/components/page/speaker/tiles/SpeakerTiles";
import ShowMoreBlock from "@/components/page/items/list/ShowMoreBlock";
export default {
  name: "SpeakersBlock",
  components: { ShowMoreBlock, SpeakerTiles },
  props: {
    data: Array,
    showMore: {
      type: Boolean,
      default: true
    },
    updateList: Function,
    sort: {
      type: String,
      default: ""
    },
    sortType: {
      type: String,
      default: ""
    },
    page: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style scoped></style>
