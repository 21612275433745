<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <h1 class="header-big">Спикеры</h1>
      <!--      <catalog-menu />-->
      <speakers-block
        :data.sync="speakers"
        :update-list="loadSpeakers"
        :page="page"
      />
    </div>
  </div>
</template>

<script>
import SpeakersBlock from "@/components/page/speaker/SpeakersBlock";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Speakers",
  components: { SpeakersBlock },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapGetters(["speakers"])
  },
  methods: {
    ...mapActions(["getSpeakers"]),
    loadSpeakers(sort, direction, page) {
      if (page) {
        this.page = page;
      }
      return this.getSpeakers(this.page);
    }
  },
  created() {
    this.loadSpeakers();
  }
};
</script>

<style scoped></style>
